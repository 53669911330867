import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getClassNames } from '../util'
import ProductGrid from "../components/store/productGrid"
import ProductSearch from "../components/store/productSearch"
import contentParser from 'gatsby-wpgraphql-inline-images';
import useProductSearch from '../hooks/useProductSearch'

const ProductsPage = (props) => {
    const { pageContext } = props
    const { title, content, acfGlobalPages, uri } = pageContext.store
    const { products, tag } = pageContext
    const wpContent = content ? contentParser({ content }, pageContext.plugins.wpImage) : undefined;
    const layoutClasses = getClassNames(acfGlobalPages.sidebarLeft, acfGlobalPages.sidebarRight)
    const { changeFilterTags, filterTags } = useProductSearch();

    return (
        <Layout>
            <SEO title={title} description={acfGlobalPages.metaDescription} />
            <div className="container mx-auto p-6 py-16">

                <div className="flex flex-wrap">

                    { layoutClasses.sidebarLeft &&
                    <section className={`${layoutClasses.sidebarLeft} p-3 pt-8`}>
                        <div className="md:border-r p-4">
                            { !tag && 
                                <ProductSearch 
                                    products={products}
                                    changeFilterTags={changeFilterTags} 
                                />
                            }
                            { tag && 
                                <Link to="/shop/" className="block text-center px-2 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-red-900 focus:shadow-outline-indigo transition duration-150 ease-in-out">
                                    All Product
                                </Link>
                            }
                            <div dangerouslySetInnerHTML={{__html: acfGlobalPages.sidebarLeft.itemOne}} />
                            <div dangerouslySetInnerHTML={{__html: acfGlobalPages.sidebarLeft.itemTwo}} />
                        </div>
                    </section>
                    }

                    <section className={`${layoutClasses.main} p-3`}>                  
                        <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
                            {title} { tag && tag.name }
                        </h1>
                        <div id="main-content-block">
                            <section className="content-block">{wpContent}</section>
                            <ProductGrid 
                                storeUri={uri} 
                                products={products} 
                                filterTags={filterTags} 
                            />
                        </div>
                        
                    </section>

                    { layoutClasses.sidebarRight &&
                    <section className={`${layoutClasses.sidebarRight} p-3 pt-8`}>
                        <div className="md:border-l p-4">
                            <div className="content-block" dangerouslySetInnerHTML={{__html: acfGlobalPages.sidebarRight.itemOne}} />
                            <div className="content-block" dangerouslySetInnerHTML={{__html: acfGlobalPages.sidebarRight.itemTwo}} />
                        </div>
                    </section>
                    }

                </div>

            </div>
      </Layout>
    )
}

export default ProductsPage