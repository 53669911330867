import { useState } from 'react';

function useProductSearch() {
  const [filterTags, setFilterTags] = useState([]);

  const updateFilterTags = (id, remove) => {
      let tags = [...filterTags];
      
      // always remove
      tags = tags.filter(item => item !== id)

      // Add Back if adding
      if (!remove) {
        tags.push(id)
      }
      
      // update state
      setFilterTags([...tags])
  }

  return {
      changeFilterTags: (id, remove) => updateFilterTags(id, remove),
      filterTags
  }
}

export default useProductSearch;
