import React from "react"

function ProductSearch({ products, changeFilterTags }) {

    const getTags = () => {
        const tags = []
        const counts = {}

        products.forEach(product => {
            product.tags.nodes.forEach(tag => {
                const {name, slug, termTaxonomyId} = tag
                
                if (!counts[tag.slug]) {
                    tags.push({
                        name, 
                        slug, 
                        id: termTaxonomyId, 
                        count: 0,
                    })
                    counts[tag.slug] = 0
                }

                counts[tag.slug] += 1
            })
        });

        // update counts
        tags.forEach((tag, index, allTags)  => {
            allTags[index].count = counts[tag.slug]
        })

        return tags
    }

    const handleChange = (e) => {
        const id = parseInt(e.target.id)
        const remove = !e.target.checked
        changeFilterTags(id, remove)
    }

    return (
        <div className="">
            <h3 className="block text-sm font-bold tracking-widest uppercase mb-6 text-slategray">
                Category
            </h3>
            <ul className="block">
                {getTags().map(tag => (
                <li key={tag.id.toString()} className="mt-2" >
                    <label>
                        <input 
                            type="checkbox" 
                            className="form-checkbox h-5 w-5 text-gray-600"
                            name={tag.slug}
                            id={tag.id}
                            onChange={handleChange}
                        />
                        <span className="ml-2 text-gray-700">
                            {tag.name}
                            { // ({tag.count})
                            }
                        </span>
                    </label>
                </li>
                ))}
            </ul>
        </div>
    )
}

export default ProductSearch
